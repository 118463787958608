<template>
  <div :class="['loginContainer', isLoginLocked ? 'loginLockedContainer' : '']">
    <GainSightPX :anonymous="true" />
    <div>
      <img
        style="display:block; margin-left: auto; margin-right: auto; width:80px; height: 80px; object-fit: contain"
        :src="logo_url || '/Five9Logo.jpg'"
        @error="handleAltImg"
      />
    </div>
    <div class="login-title"></div>
    <div v-if="!isLoginLocked">
      <el-form
        ref="loginForm"
        :rules="rules"
        label-position="top"
        label-width="100px"
        :model="credentials"
        hide-required-asterisk
      >
        <el-form-item
          class="email-form-item text"
          prop="email"
          :label="__('Email address')"
        >
          <el-input id="email" name="email" v-model="credentials.email" />
        </el-form-item>

        <el-form-item
          class="password-form-item text"
          label="temp"
          prop="password"
        >
          <span slot="label">
            {{ __("Password") }}
          </span>
          <span
            slot="label"
            @click="redirectToForgotPassword"
            class="que text forgot-pwd-text"
          >
            {{ __("Forgot password?") }}
          </span>
          <el-input
            id="password"
            name="password"
            v-model="credentials.password"
            @keyup.enter.native.prevent.stop="submitForm"
            type="password"
            autocomplete="off"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            id="sign-in-btn"
            class="w-full text"
            :loading="isSubmitting"
            @click="submitForm"
          >
            {{ __("Sign in") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-else class="login-lock-info">
      <span>
        {{ lockedMessage }}
      </span>
    </div>
  </div>
</template>

<script>
import { validEmail } from "@/utils/validate";
import { mapActions, mapState } from "vuex";
import Vue from "vue";
import store from "@/store";
import { socialProviderAuth } from "@/api/auth";
import { getQueryParams } from "@/utils/transformers";
import _ from "lodash";
import Translation from "@/mixins/translation";
import OktaClient from "@/utils/OktaClient";
import GainSightPX from "@/components/lib/GainSightPX.vue";
import localStorageSession from "@/utils/localStorageManager";

export default {
  name: "LoginForm",
  components: { GainSightPX },
  mixins: [Translation],
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!validEmail(value)) {
        callback(new Error(__("Please enter valid email")));
      } else {
        callback();
      }
    };
    return {
      lockedMessage: "",
      credentials: {
        email: "",
        password: ""
      },
      rules: {
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
        password: [
          {
            required: true,
            trigger: "blur",
            message: __("Password is required")
          }
        ]
      },
      isSubmitting: false,
      isLoginLocked: false
    };
  },
  props: {
    redirect: {
      type: String,
      default: "",
      required: false
    }
  },
  mounted() {
    if (this.launchQuery === "okta") {
      // eslint-disable-next-line
      this.loginLocked = __("Redirecting you to Okta...");

      // Since login flow is now controlled by Okta,
      // we can close the classic login form after launching Okta
      return this.redirectToOktaAuthFromAdminConsole();
    }

    this.isLoginLocked = this.loginLocked;
  },
  computed: {
    /**
     * Get login provider to launch
     */
    launchQuery() {
      return _.get(this.$route, "query.launch");
    },
    loginLocked: {
      get: function() {
        let login_status = localStorageSession.get("login-failed");
        if (!login_status) {
          return false;
        }
        login_status = JSON.parse(login_status);
        const now = new Date();
        // compare the expiry time of the item with the current time
        if (now.getTime() > login_status.expiry) {
          // If the item is expired, delete the item from storage and allow login
          localStorageSession.remove("login-failed");
          return false;
        }
        this.setLoginLockedMessage(login_status.value);
        return true;
      },
      set: function(value) {
        this.setLoginLockedMessage(value);
        this.isLoginLocked = true;
      }
    },
    ...mapState("app", {
      logo_url: state => state.logo_url,
      subdomain: state => state.subdomain
    })
  },

  methods: {
    ...mapActions("auth", {
      login: "login"
    }),
    redirectToOktaAuth() {
      socialProviderAuth("okta", this.redirect);
    },

    /**
     * Authenticate user that launch studio from admin console using Okta
     */
    redirectToOktaAuthFromAdminConsole: async function() {
      if (!OktaClient.isPKCESupported()) {
        return;
      }

      const authorize = _.get(this.$route, "query.authorize");
      const clientId = _.get(this.$route, "query.client_id");
      const redirectUrl = _.get(this.$route, "query.redirect_url");
      const loginHint = _.get(this.$route, "query.username");
      const authClient = new OktaClient(
        authorize,
        clientId,
        redirectUrl,
        loginHint
      );
      authClient.saveConfig().start();
    },

    redirectToForgotPassword() {
      this.$router.push("/forgot");
    },
    setLoginLockedMessage(message) {
      this.lockedMessage = message;
    },
    handleAltImg(event) {
      event.target.src = "/Five9Logo.jpg";
    },
    promptOtpForm(otpLinkToken) {
      this.isSubmitting = false;
      this.$router
        .push({
          name: "VerifyOtp",
          params: {
            email: this.credentials.email,
            password: this.credentials.password,
            redirectUrl: this.redirect,
            otpLinkToken
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    promptSetup2FAForm(loginData) {
      this.isSubmitting = false;
      this.$router
        .push({
          name: "SetupOtp",
          params: {
            email: this.credentials.email,
            redirectUrl: this.redirect,
            token: loginData.token,
            qr_code: loginData.qr_code
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    successFn(res) {
      if (res.data.pending_2fa_setup) {
        this.promptSetup2FAForm(res.data);
      } else if (!res.data.otp_required) {
        this.$auth.fetch().then(async userReq => {
          this.translateForUser(userReq.data);
          let obj = {};
          if (this.redirect) {
            let searchParams = getQueryParams(this.redirect);
            let ac_id = searchParams.get("ac_id");
            let sp_id = searchParams.get("sp_id");
            if (sp_id) {
              obj.sp_id = sp_id;
            }
            if (ac_id) {
              obj.ac_id = ac_id;
            }
          }
          if (!_.isEmpty(obj)) {
            await store.dispatch("app/generateUserAccountSwitcherOptions", obj);
          } else {
            await store.dispatch("app/generateUserAccountSwitcherOptions");
          }
          if (this.redirect) {
            this.$router
              .push(this.redirect)
              .then(() => {
                this.isSubmitting = false;
              })
              .catch(() => {});
          } else {
            this.$router
              .push("/")
              .then(() => {
                this.isSubmitting = false;
              })
              .catch(() => {});
          }
        });
      }
    },
    errorFn(err) {
      console.log(err);
      this.isSubmitting = false;
      let msg = __("Invalid credentials");
      if (err !== undefined) {
        msg = err.message;
        let err_data = err.response.data.data;
        if (err_data !== undefined) {
          let ttl = err_data.lockout_duration;
          ttl = new Date().getTime() + ttl * 1000;
          const item = {
            value: err.message,
            expiry: ttl
          };
          localStorageSession.set("login-failed", JSON.stringify(item));
          this.loginLocked = item.value;
        }
      }
      this.$message({
        message: msg,
        type: "error"
      });
    },
    submitForm() {
      let self = this;
      Vue.auth.requireOtp = otpLinkToken => {
        this.promptOtpForm(otpLinkToken);
      };
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          self.isSubmitting = true;
          Vue.auth.login({
            data: {
              email: self.credentials.email,
              password: self.credentials.password
            },
            fetchUser: false,
            success: this.successFn,
            error: this.errorFn
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.loginContainer {
  padding-top: 80px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  width: 476px;
  height: 498px;
  background: #ffffff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .text {
    font-weight: bold;
    font-size: 1rem;
    line-height: 19px;
  }

  .login-title {
    font-weight: bolder;
    font-size: 36px;
    display: flex;
    flex-direction: row;
    color: #454545;
    margin-bottom: 50px;
    .welcome-dot {
      text-shadow: 0 4px 4px rgba(28, 209, 161, 0.26);
      color: $--color-primary;
    }
  }

  .loginOptions {
    display: flex; /* new */
    justify-content: space-between; /* new */
    flex-direction: row;
  }

  .provider-sign-in {
    font-size: 1rem;
    color: var(--theme-color);
    background: #ffffff;
    border: 1px solid var(--theme-color);
    border-radius: 5px;
    height: 44px;
    font-weight: bold;
    display: flex;
    justify-content: center;

    ::v-deep img {
      margin: auto;
    }
  }

  ::v-deep .el-form-item--medium .el-form-item__label {
    line-height: 7px;
    font-size: 1rem;
  }

  ::v-deep .el-input--medium .el-input__inner {
    height: 44px;
    line-height: 50px;
  }

  ::v-deep .el-form-item__label {
    display: flex;
    justify-content: space-between;
  }

  ::v-deep .el-divider--horizontal {
    height: 1px;
    background: #999999;
    margin: 36px auto;
  }
  ::v-deep .el-input__inner:focus {
    border-color: var(--theme-color) !important;
    outline: 0;
  }

  .email-form-item {
    margin-bottom: 60px;
    &:hover {
      border-color: var(--theme-hover-color);
    }
  }

  .password-form-item {
    margin-bottom: 60px;
  }

  #sign-in-btn {
    border-radius: 5px;
    height: 44px;
  }

  .que {
    cursor: pointer;
  }

  .forgot-pwd-text {
    color: var(--theme-color);

    &:hover {
      color: var(--theme-hover-color);
    }
  }
}

.w-full {
  width: 100%;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: $--color-white;
  &:hover {
    color: $--color-white;
    background-color: var(--theme-hover-color);
    border-color: var(--theme-hover-color);
  }
  &:focus {
    color: var(--theme-color);
    background-color: var(--theme-hover-background-color);
    border-color: var(--theme-hover-color);
  }
}

.loginLockedContainer {
  height: auto;
  width: auto;
  padding: 5% 5%;
}

.login-lock-info {
  text-align: center;
  padding: 10px;
  line-height: 1.5rem;
  font-size: 17px;
}
</style>
